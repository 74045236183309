@pagination-item-bg: #0090c6;
@pagination-item-color: #0090c6;

.ant-pagination-item-link svg {
  color: #fff;
}
.ant-pagination-disabled {
  background-color: #0090c6;
}
.ant-pagination {
  &.mini {
    .ant-pagination-item,
    .ant-pagination-next,
    .ant-pagination-prev {
      margin-right: 8px;
    }
  }

  & &-item {
    background-color: @pagination-item-bg;
    border-color: @pagination-item-bg;

    a {
      color: @pagination-item-color;
      font-size: 0.8rem;
    }

    &:hover {
      a {
        color: @primary-color;
        font-weight: bold;
      }
    }

    &:active,
    &.ant-pagination-item-active {
      background-color: @pagination-item-bg;
      border-color: @pagination-item-bg;

      a {
        color: @white;
      }
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    .ant-pagination-item-link {
      background-color: @pagination-item-bg;
      border-color: @pagination-item-bg;
      color: @pagination-item-color;
    }

    &:hover,
    &:focus {
      .ant-pagination-item-link {
        background-color: @primary-color;
        border-color: @primary-color;
        color: @white;
      }
    }
  }

  & &-disabled {
    .ant-pagination-item-link {
      background-color: @pagination-item-bg;
      border-color: @pagination-item-bg;
      color: @pagination-item-color;
    }

    &:hover,
    &:focus {
      .ant-pagination-item-link {
        background-color: @pagination-item-bg;
        border-color: @pagination-item-bg;
        color: @pagination-item-color;
      }
    }
  }

  & &-options {
    & .ant-select {
      .ant-select-selector {
        background-color: @pagination-item-bg;
        border-color: @pagination-item-bg;
      }
    }
  }
}
