@space: 1rem;
@spaces: @space / 4, @space / 2, @space, @space * 1.5, @space * 3;
@space-number: 5;

.make-spacing(@class, @property, @prefix, @length, @i: 1) when (@i =< @length) {
  .@{class}@{prefix}-@{i} {
    @{property}: extract(@spaces, @i);
  }
  .@{class}t@{prefix}-@{i},
  .@{class}y@{prefix}-@{i} {
    @{property}-top: extract(@spaces, @i) !important;
  }
  .@{class}r@{prefix}-@{i},
  .@{class}x@{prefix}-@{i} {
    @{property}-right: extract(@spaces, @i) !important;
  }
  .@{class}b@{prefix}-@{i},
  .@{class}y@{prefix}-@{i} {
    @{property}-bottom: extract(@spaces, @i) !important;
  }
  .@{class}l@{prefix}-@{i},
  .@{class}x@{prefix}-@{i} {
    @{property}-left: extract(@spaces, @i) !important;
  }

  .@{class}@{prefix}-n@{i} {
    @{property}: extract(@spaces, @i) * -1 !important;
  }
  .@{class}t@{prefix}-n@{i},
  .@{class}y@{prefix}-n@{i} {
    @{property}-top: extract(@spaces, @i) * -1 !important;
  }
  .@{class}r@{prefix}-n@{i},
  .@{class}x@{prefix}-n@{i} {
    @{property}-right: extract(@spaces, @i) * -1 !important;
  }
  .@{class}b@{prefix}-n@{i},
  .@{class}y@{prefix}-n@{i} {
    @{property}-bottom: extract(@spaces, @i) * -1 !important;
  }
  .@{class}l@{prefix}-n@{i},
  .@{class}x@{prefix}-n@{i} {
    @{property}-left: extract(@spaces, @i) * -1 !important;
  }

  .make-spacing(@class, @property, @prefix, @length, (@i + 1));
}

.make-spacing(m, margin, ~"", @space-number);
.make-spacing(p, padding, ~"", @space-number);

@media (min-width: @screen-sm-min) {
  .make-spacing(m, margin, -sm, @space-number);
  .make-spacing(p, padding, -sm, @space-number);
}

@media (min-width: @screen-md-min) {
  .make-spacing(m, margin, -md, @space-number);
  .make-spacing(p, padding, -md, @space-number);
}

@media (min-width: @screen-lg-min) {
  .make-spacing(m, margin, -lg, @space-number);
  .make-spacing(p, padding, -lg, @space-number);
}

@media (min-width: @screen-xl-min) {
  .make-spacing(m, margin, -xl, @space-number);
  .make-spacing(p, padding, -xl, @space-number);
}

@media (min-width: @screen-xxl-min) {
  .make-spacing(m, margin, -xxl, @space-number);
  .make-spacing(p, padding, -xxl, @space-number);
}
