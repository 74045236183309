.ant-radio {
  &-group {
    // Custom button group
    &.custom {
      .ant-radio-button-wrapper {
        margin-right: 0.5rem;
        border-radius: @border-radius-base;
        border-width: 1;
        background-color: white;
        border-left-width: 1px !important;

        &::before {
          display: none;
        }

        &.ant-radio-button-wrapper-checked {
          background-color: @primary-color;
          color: @white;
          font-weight: 500;
        }
      }
    }
  }

  &-inner {
    background-color: darken(@light-color, 5%) !important;
    border-color: darken(@light-color, 5%) !important;
  }

  &-checked &-inner {
    border-color: @primary-color !important;
    background-color: @primary-color !important;

    &::after {
      background-color: darken(@light-color, 5%);
    }
  }

  &-wrapper:hover &-inner {
    border-color: @primary-color !important;
  }
}
