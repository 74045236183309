.ant-checkbox {
  &-inner {
    background-color: darken(@light-color, 5%);
    border-color: darken(@light-color, 5%);
  }

  &-indeterminate &-inner {
    background-color: @primary-color;
    border-color: @primary-color;

    &::after {
      background-color: darken(@light-color, 5%);
    }
  }
}
