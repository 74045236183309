@layout-primary-color: darken(@primary-color, 10%);
@layout-text-color: @black;
@layout-text-active-color: @primary-color;
@header-background: @layout-header-background;
@header-tab-bg-color: @white;

@sidebar-width: 250px;
@sidebar-bg-color: @light-color;
@sidebar-text-color: @white;
@sidebar-menu-item-bg-color: @white;
@sidebar-menu-item-color: @white;
@sidebar-menu-item-icon-color: @white;

@sidebar-menu-item-hover-bg-color: @white;
@sidebar-menu-item-hover-color: @black;
@sidebar-menu-item-icon-hover-color: @white;

@sidebar-menu-item-active-bg-color: #d7dfe7;
@sidebar-menu-item-active-color: #4d4d4d;
@sidebar-menu-item-icon-active-color: @primary-color;
@sidebar-menu-item-group-color: @black;

@sidebar-submenu-color: lighten(@white, 5%);
@sidebar-submenu-hover-color: @white;
@sidebar-submenu-hover-bg-color: darken(@white, 5%);
@sidebar-submenu-active-color: @white;
@sidebar-submenu-active-bg-color: @primary-color;
@sidebar-submenu-icon-color: @black;
@sidebar-submenu-icon-hover-color: @black;
@sidebar-submenu-icon-active-color: @primary-color;

@collapsed-sider-width: 0px;

@content-bg-color: @bg-color;

.cancel-btn {
  border-color: rgba(57, 63, 72, 0.3) !important;
}

.ant-btn-primary {
  background: #0090c6;
  border-color: #0090c6;
}
#dashboard-layout {
  min-height: 100vh;

  // Header
  .ant-layout-header {
    z-index: 1000;
    padding: 0 1rem;
    line-height: 0 !important;

    &.site-layout-background {
      position: fixed;
      width: 100%;
      background: #fff !important;
      -webkit-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
      -moz-box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
      box-shadow: 0 3px 5px rgba(57, 63, 72, 0.3);
    }

    a {
      color: @layout-text-color;
    }

    .logo {
      height: 64px;
      a {
        height: 64px;
        // background: @header-background;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        // padding: 0 0.5rem;

        img {
          width: calc(64px - 1px);
        }

        // span {
        //   font-size: x-large;
        //   font-weight: normal;
        //   color: @layout-text-color;
        // }

        // img + span {
        //   margin-left: 1rem;
        // }
      }
    }

    // Search input
    .search-input {
      background-color: @light-color;
      border-color: @light-color;

      &.ant-input-affix-wrapper-focused {
        box-shadow: none;
      }
    }
  }

  h5.ant-typography {
    color: #202223 !important;
  }

  .header-username {
    font-weight: 500;
  }

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow {
    color: #4d4d4d !important;
  }

  // End header

  // Sidebar
  .ant-layout-sider {
    background-color: @sidebar-bg-color;

    &.first-sider,
    &.second-sider {
      overflow: auto;
      height: 100vh;
      position: fixed;
      left: 0;
      border-right: 2px solid darken(@light-color, 5%);
    }

    &.first-sider {
      padding-top: 1rem;
      width: @collapsed-sider-width !important;
      flex: 0 0 @collapsed-sider-width !important;
      max-width: @collapsed-sider-width !important;
      min-width: @collapsed-sider-width !important;
    }

    &.second-sider {
      left: @collapsed-sider-width;
      top: 0;
      padding-right: 0.5rem;
      padding-right: 0.5rem;
      padding-top: 72px;
      z-index: 1;
      background-color: #f3f6f9;

      .ant-layout-sider-children {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }

    &:not(.ant-layout-sider-collapsed) {
      flex: 0 0 @sidebar-width !important;
      min-width: @sidebar-width !important;
      max-width: @sidebar-width !important;
      width: @sidebar-width !important;
    }
    .ant-menu-item-selected a,
    .ant-menu-item-selected a:hover {
      color: #05529a !important;
    }

    .ant-menu {
      // background-color: @sidebar-bg-color;
      background-color: #f3f6f9;
      border: 0;

      .ant-menu-submenu {
        color: #4d4d4d;

        .ant-menu-submenu-title {
          font-weight: 500;
          display: flex;
          align-items: center;

          .ant-menu-submenu-arrow {
            &::before,
            &::after {
              background-color: #4d4d4d;
              font-weight: 400 !important;
            }
          }

          .anticon,
          svg {
            font-size: large;
            color: @sidebar-submenu-icon-color;
          }

          &:hover {
            background-color: @sidebar-submenu-hover-bg-color;
            color: #4d4d4d;

            .anticon,
            svg {
              color: @sidebar-submenu-icon-hover-color;
            }
          }
        }

        &.ant-menu-submenu-open {
          .ant-menu-submenu-title {
            color: #4d4d4d;

            .ant-menu-submenu-arrow {
              &::before,
              &::after {
                background-color: @sidebar-submenu-active-color;
              }
            }

            .anticon,
            svg {
              color: @sidebar-submenu-icon-active-color;
            }

            &:hover {
              color: #4d4d4d;
            }
          }
        }
      }

      // Item group
      .ant-menu-item-group-title {
        font-weight: 500;
        color: @sidebar-menu-item-group-color;

        .anticon,
        svg {
          font-size: large;
          color: @sidebar-menu-item-group-color;
        }
      }

      .ant-menu-item {
        width: calc(100% - 10px);
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        color: @sidebar-menu-item-color;
        display: flex;
        align-items: center;
        &::after {
          border-right-color: transparent;
        }

        > span > a {
          color: #4d4d4d;
          font-weight: 500;
        }

        > .anticon,
        svg {
          font-size: large;
          color: @sidebar-menu-item-icon-color;
        }

        &.ant-menu-item-selected {
          background-color: @sidebar-menu-item-active-bg-color;
          color: @sidebar-menu-item-icon-active-color;

          > .anticon,
          svg {
            color: @sidebar-menu-item-icon-active-color;
          }

          > span > a {
            font-weight: 500;
            color: #4d4d4d;
          }
        }

        &.ant-menu-item-active:not(.ant-menu-item-selected) {
          color: @sidebar-menu-item-icon-hover-color;
          background-color: #e9edf1;

          > .anticon,
          svg {
            color: @sidebar-menu-item-icon-hover-color;
          }

          > span > a {
            color: #4d4d4d;
            font-weight: 500;
          }
        }
      }

      &.ant-menu-inline-collapsed {
        > .ant-menu-item {
          width: 40px;
          padding: 0;
          text-align: center;
        }
      }
    }

    .ant-layout-sider-trigger {
      height: 30px !important;
      width: 30px !important;
      background-color: @primary-color;
      border-radius: @border-radius-base;
      left: calc(@collapsed-sider-width + @sidebar-width);
      bottom: 40px;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
    }

    &.ant-layout-sider-collapsed {
      .ant-layout-sider-trigger {
        left: @collapsed-sider-width;
        transform: translateX(0);
      }

      &.second-sider {
        width: 0 !important;
        min-width: 0 !important;
        padding: 0;
      }

      & + .right-layout {
        margin-left: @collapsed-sider-width;

        .page-header-container {
          @media screen and(min-width: 992px) {
            width: calc(100vw - @collapsed-sider-width);
          }
        }

        .container {
          width: 100%;
          margin: 0 auto;
          @media screen and(min-width: 1200px) {
            max-width: 1140px;
          }
          @media screen and(min-width: 1400px) {
            max-width: 1340px;
          }
        }
      }

      .ant-menu-item {
        .ant-menu-item-icon {
          margin-right: 0;
        }
      }
    }
  }

  .right-layout {
    margin-left: @collapsed-sider-width + @sidebar-width;
    transition: all 0.2s;
    background-color: @content-bg-color;

    .page-header-container {
      padding: 0;
      padding-top: 0.5rem;
    }
  }

  &.fixed-header {
    @media screen and(min-width: 992px) {
      .ant-layout-header {
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 1000;
        background-color: @white;
      }

      .right-layout {
        .page-header-container .container {
          margin-top: 72px !important;
        }
      }
    }
    @media screen and(max-width: 480px) {
      .sm-title {
        display: none !important;
      }
    }
  }

  hr {
    border-top: 1px solid #c9cccf !important;
  }

  .timeline {
    border-left: 2px solid #f0f0f0;
    margin-left: 1.25rem;
    padding: 40px 0;
    margin-top: -22px;
  }

  .timeline-text {
    padding-left: 1.2rem;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .ql-editor {
    min-height: 12em;
    padding: 0 !important;
    border-radius: 5px;
  }

  .ql-container {
    padding: 1rem;
  }
}
