body {
  font-weight: 400;
  font-family: Roboto;
}

// Page title
.ant-page-header-heading-title {
  font-weight: 500 !important;
  font-size: 1.5rem;
  font-family: "Roboto";
}
