.cursor-pointer {
  cursor: pointer;
}

.border-bottom-1 {
  border-bottom: 1px solid #c9cccf !important;
}

.ant-checkbox-group-item {
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-top: 0.8em;
}

.mg-err .ant-form-item-with-help {
  margin-bottom: 24px !important;
}

.ant-checkbox + span {
  padding-right: 0.5em;
  padding-left: 0.8em;
  padding-top: 0.5em;
}

.ant-popover-arrow {
  border-style: none;
  height: 0 !important;
  width: 0 !important;
}

.popover-colors {
  overflow-x: hidden !important;
  overflow: scroll;
  background-color: #ffffff;
}

@media screen and(max-width: 480px) {
  .ant-mobile {
    padding-top: 1.5rem !important;
  }
}
